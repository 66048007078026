import { injectable } from 'inversify-props';
import dayjs from '@/plugins/dayjs';
import BaseService from '@/services/base-service';
import AttendanceModel from '@/models/crm/attendance.model';
import { RequestConfig } from '@/interfaces/request-config.interface';
import { ClientTypeEnum } from '../../enums/client-type.enum';

@injectable()
export default class AttendanceService extends BaseService {
  async generate(
    clientId: string,
    type: ClientTypeEnum,
    index: string,
    initialDate: string,
    finalDate: string,
  ): Promise<number> {
    const config: RequestConfig = {
      params: {
        clientId,
        index,
        type,
        initialDate,
        finalDate,
      },
    };
    return (await this.httpService.get(Number, `${this.getApiPath()}/crm/generate-attendance`, config)) as number;
  }

  async get(
    clientId: string,
    type: ClientTypeEnum,
    index: string,
    limit: number,
    offset = 0,
    initialDate: string,
    finalDate: string,
  ): Promise<AttendanceModel[]> {
    const config: RequestConfig = {
      params: {
        clientId,
        index,
        limit,
        offset,
        type,
        initialDate,
        finalDate,
      },
    };
    return (await this.httpService.get(
      AttendanceModel,
      `${this.getApiPath()}/crm/attendance`,
      config,
    )) as AttendanceModel[];
  }

  static generateAttendanceExportFilename(date: Date): string {
    return `Empresa_Atendimentos_${dayjs(date).format('YYYY-MM-DD_HH-mm-ss')}.xlsx`;
  }
}
