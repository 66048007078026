export default class SettingsModel {
  flagHabilitaNovoPainelCliente!: boolean;

  flagConverteProspectIGS!: boolean;

  flagIntegraClienteAposConversaoProspect!: boolean;

  flagUsaSegundaViaBoletos!: boolean;

  flagIntegracaoSintegraAtivo!: boolean;

  urlCrmApp!: string;

  flagObrigatoriedadeFechamentoConversa!: boolean;

  flagTornarCnpjProspectObrigatorio!: boolean;

  flagUtilizaWhatsapp!: boolean;

  flagUtilizaAgenda!: boolean;

  loggedUserType!: number;

  flagPermiteExcluirAnexos!: boolean;
}
