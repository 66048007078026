export default class MetasClienteModel {
  orgHierarchy = Array<string>();

  orgHierarchyString = this.orgHierarchy.toString();

  id = BigInt(0);

  nivel = 0;

  nome = '';

  codRepres = '';

  codCli = '';

  codLinhaProd = '';

  cnpj = '';

  situacaoCadastro = 0;

  situacaoContrato = 1;

  tipoCalcMeta = 1;

  metaPeriodo = 0;

  metaQuantidade = 0;

  metaValor = 0;

  constructor(orgHierarchy: Array<string>, orgHierarchyString: string, id: bigint, nivel: number, nome: string,
    codRepres: string, codCli: string, codLinhaProd: string, cnpj: string, situacaoCadastro: number,
    situacaoContrato: number, metaPeriodo: number, tipoCalcMeta: number, metaQuantidade: number, metaValor: number) {
    this.orgHierarchy = orgHierarchy;
    this.orgHierarchyString = orgHierarchyString;
    this.id = id;
    this.nivel = nivel;
    this.nome = nome;
    this.codRepres = codRepres;
    this.codCli = codCli;
    this.codLinhaProd = codLinhaProd;
    this.cnpj = cnpj;
    this.situacaoCadastro = situacaoCadastro;
    this.situacaoContrato = situacaoContrato;
    this.tipoCalcMeta = tipoCalcMeta;
    this.metaPeriodo = metaPeriodo;
    this.metaQuantidade = metaQuantidade;
    this.metaValor = metaValor;
  }
}
