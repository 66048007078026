import { injectable } from 'inversify-props';
import { classToPlain } from 'class-transformer';
import { cloneDeep } from 'lodash';
import BaseService from '@/services/base-service';
import ProspectModel from '@/models/crm/prospect.model';
import { RequestConfig } from '@/interfaces/request-config.interface';

@injectable()
export default class ProspectService extends BaseService {
  async create(model: ProspectModel): Promise<number> {
    return (await this.httpService.post(
      Number,
      `${this.getApiPath()}/prospect?fromPanel=true`,
      classToPlain(cloneDeep(model)),
    )) as number;
  }

  async update(model: ProspectModel): Promise<ProspectModel> {
    return (await this.httpService.put(
      ProspectModel,
      `${this.getApiPath()}/prospect/${model.codProspect}`,
      classToPlain(cloneDeep(model)),
    )) as ProspectModel;
  }

  async quickSearch(keyword: string): Promise<ProspectModel[]> {
    return (await this.httpService.get(
      ProspectModel,
      `${this.getApiPath()}/prospect/quick-search?term=${encodeURIComponent(keyword).toString()}`,
    )) as ProspectModel[];
  }

  async getByCnpj(cnpj: string): Promise<ProspectModel> {
    const config: RequestConfig = {
      params: {
        cnpj,
      },
    };
    return (await this.httpService.get(ProspectModel, `${this.getApiPath()}/prospect`, config)) as ProspectModel;
  }

  async get(id: number): Promise<ProspectModel> {
    const config: RequestConfig = {
      params: {
        id,
      },
    };
    return (await this.httpService.get(ProspectModel, `${this.getApiPath()}/prospect`, config)) as ProspectModel;
  }

  async transformToClient(codProspect: string): Promise<string> {
    return (await this.httpService.post(
      String,
      `${this.getApiPath()}/prospect/transform-to-client/${codProspect}`,
    )) as string;
  }

  async getAddressByCep(prospect: ProspectModel): Promise<ProspectModel> {
    const prospectAux = classToPlain(cloneDeep(prospect));
    return (await this.httpService.post(
      ProspectModel,
      `${this.getApiPath()}/prospect/find-address`,
      prospectAux,
    )) as ProspectModel;
  }

  delete(id: string): Promise<void> {
    return this.httpService.delete(`${this.getApiPath()}/prospect/${id}`);
  }
}
