import { Transform } from 'class-transformer';
import { EmailOriginEnum } from '@/enums/crm/email-origin.enum';
import { DateTransform } from '@/utils/value-transformers/date.transform';

export default class EmailModel {
  id!: number;

  @Transform(DateTransform)
  inclusaoData!: Date;

  remetente!: string;

  destinatarios!: string[];

  copias!: string[];

  assunto!: string;

  mensagem!: string;

  origem!: EmailOriginEnum;

  idAtendente!: number;

  nomeAtendente!: string;
}
