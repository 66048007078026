import { injectable } from 'inversify-props';
import { classToPlain, plainToClass } from 'class-transformer';
import { cloneDeep } from 'lodash';
import BaseService from '@/services/base-service';
import UserContactInfo from '@/models/crm/user-contact-info.model';
import EmailModel from '@/models/email.model';
import ContactTypeModel from '@/models/crm/contact-type.model';
import ContactModel from '@/models/crm/contact.model';
import { RequestConfig } from '@/interfaces/request-config.interface';
import { ClientTypeEnum } from '../../enums/client-type.enum';

@injectable()
export default class ContactService extends BaseService {
  async create(model: ContactModel): Promise<ContactModel> {
    return (await this.httpService.post(
      ContactModel,
      `${this.getApiPath()}/crm/contato`,
      classToPlain(cloneDeep(model)),
    )) as ContactModel;
  }

  async update(model: ContactModel): Promise<ContactModel> {
    return (await this.httpService.put(
      ContactModel,
      `${this.getApiPath()}/crm/contato/${model.id}`,
      classToPlain(cloneDeep(model)),
    )) as ContactModel;
  }

  delete(id: number): Promise<void> {
    return this.httpService.delete(`${this.getApiPath()}/crm/contato/${id}`);
  }

  async getContact(id: number): Promise<ContactModel> {
    return (await this.httpService.get(ContactModel, `${this.getApiPath()}/crm/contato/${id}`)) as ContactModel;
  }

  async getContacts(clientId: string, type: ClientTypeEnum): Promise<ContactModel[]> {
    const config: RequestConfig = {
      params: {
        clientId: clientId.replace(/\D/g, ''),
        type,
      },
    };

    return (await this.httpService.get(
      ContactModel,
      `${this.getApiPath()}/crm/contato/list`,
      config,
    )) as ContactModel[];
  }

  async getContactTypes(): Promise<ContactTypeModel[]> {
    return (await this.httpService.get(
      ContactTypeModel,
      `${this.getApiPath()}/crm/tipo-contato/list`,
    )) as ContactTypeModel[];
  }

  async getLoggedUserContactInfo(): Promise<UserContactInfo> {
    return (await this.httpService.get(
      UserContactInfo,
      `${this.getApiPath()}/crm/contato/user-contact-info`,
    )) as UserContactInfo;
  }

  async sendEmail(model: EmailModel): Promise<EmailModel> {
    const modelAux = plainToClass(EmailModel, {
      cnpj: model.cnpj,
      prospect: model.prospect,
      from: model.from,
      to: model.to,
      cc: model.cc,
      subject: model.subject,
      sendCopy: model.sendCopy,
      message: model.message,
      attachments: model.attachments,
    });

    return (await this.httpService.post(
      EmailModel,
      `${this.getApiPath()}/crm/contato/send-email`,
      classToPlain(cloneDeep(modelAux)),
    )) as EmailModel;
  }

  async getContactByClientId(
    contactNumber: string,
    clientId: string,
    type: ClientTypeEnum,
  ): Promise<ContactModel | undefined> {
    const contacts = await this.getContacts(clientId, type);
    const contact = contacts.find((item) => {
      if (item.whatsapp && ContactService.checkNumber(item.whatsapp, contactNumber)) {
        return true;
      }
      if (item.telefone && ContactService.checkNumber(item.telefone, contactNumber)) {
        return true;
      }

      return false;
    });

    return contact;
  }

  static checkNumber(contactNumber: string, targetNumber: string): boolean {
    const parsedTargetNumber = targetNumber.replace(/\D/g, '');
    const parsedContactNumber = contactNumber.replace(/\D/g, '');

    let alternativeContactNumber = '';

    if (parsedContactNumber.length > 10) {
      alternativeContactNumber = `${parsedContactNumber.substring(0, 2)}${parsedContactNumber.slice(3)}`;
    } else {
      alternativeContactNumber = `${parsedContactNumber.substring(0, 2)}9${parsedContactNumber.substring(2)}`;
    }

    if (parsedContactNumber === parsedTargetNumber || alternativeContactNumber === parsedTargetNumber) {
      return true;
    }

    return false;
  }
}
