import { Expose } from 'class-transformer';
import { IKeyValue } from '@/interfaces/key-value.interface';
import ProspectModel from '@/models/crm/prospect.model';
import Base64AttachmentModel from './base64attachment.model';

export default class EmailModel<T = IKeyValue> {
  cnpj!: string;

  prospect!: ProspectModel;

  @Expose({ name: 'remetente', toPlainOnly: true })
  from!: string;

  @Expose({ name: 'destinatarios', toPlainOnly: true })
  to!: string[];

  @Expose({ name: 'copias', toPlainOnly: true })
  cc?: string[];

  @Expose({ name: 'assunto', toPlainOnly: true })
  subject!: string;

  @Expose({ name: 'mensagem', toPlainOnly: true })
  message!: string;

  @Expose({ name: 'enviarCopiaUsuario', toPlainOnly: true })
  sendCopy!: boolean;

  @Expose({ name: 'enviarAnexos', toPlainOnly: true })
  sendAttachments!: boolean;

  @Expose({ name: 'anexos', toPlainOnly: true })
  attachments?: Base64AttachmentModel[];

  @Expose({ name: 'metadados', toPlainOnly: true })
  metadata?: T;
}
